<template>
    <div class="vue-template">
      <div class="title-path">
        <a href="/">Home</a>
        <small class="fas fa-angle-right mx-1"></small>
        <a href="/tickets">Tickets</a>
      </div>
      <div class="mb-2 d-block d-lg-none d-md-none">
        <div class="btn btn-sm btn-block form-control btn-primary p-2" v-on:click="newTicket">
          <i class="fas fa-plus"></i>&nbsp;&nbsp;&nbsp;New Ticket
        </div>
        <a class="btn btn-sm btn-block form-control btn-primary mt-2 p-2" href="tickets/import" v-if="['Manager', 'Admin'].includes(this.user.role)">
          <i class="fas fa-plus"></i>&nbsp;&nbsp;&nbsp;Import Tickets
        </a>
        <div class="btn btn-sm btn-block form-control btn-dark bg-escalated border-0 mt-2 p-2" v-on:click="toggleLookUp" v-if="this.user.role !== 'Customer'">
          <i class="fas fa-search"></i>&nbsp;&nbsp;&nbsp;Ticket lookup
        </div>
      </div>
      <div class="row ticket-actions">
        <div class="col left-column">
          <div class="ticket-tab" v-on:click="setParamsList('all')" v-bind:class="[ticketTab === 'all' ? 'active' : '']">
            All<span>{{ticketStatistics.totalCount}}</span>
          </div>
          <div class="ticket-tab" v-on:click="setParamsList('open')" v-bind:class="[ticketTab === 'open' ? 'active' : '']">
            Open<span>{{ticketStatistics.totalOpen}}</span>
          </div>
          <div class="ticket-tab" v-on:click="setParamsList('closed')" v-bind:class="[ticketTab === 'closed' ? 'active' : '']">
            Closed<span>{{ticketStatistics.totalClosed}}</span>
          </div>
        </div>
        <div class="col right-column d-none d-lg-block d-md-block">
          <div class="btn btn-sm btn-dark bg-escalated border-0" v-on:click="toggleLookUp" v-if="this.user.role !== 'Customer'">
            <i class="fas fa-search"></i>&nbsp;&nbsp;&nbsp;Ticket lookup
          </div>&nbsp;
          <div class="btn-group" role="group" v-if="['Manager', 'Admin'].includes(this.user.role)">
            <div class="btn btn-sm btn-primary" v-on:click="newTicket">
              <i class="fas fa-plus"></i>&nbsp;&nbsp;&nbsp;New Ticket
            </div>
            <button type="button" class="btn btn-sm btn-primary bg-primary-default border-primary-default" v-on:click="toggleImport">
              <i class="fas fa-caret-down"></i>
            </button>
          </div>
          <div class="btn btn-sm btn-primary border-0" v-on:click="newTicket" v-else>
            <i class="fas fa-plus"></i>&nbsp;&nbsp;&nbsp;New Ticket
          </div>
          <div class="cancel-menu" v-on:click="toggleImport" v-if="showImport"></div>
          <div style="width: 180px; position: absolute; right: 30px; margin-top: 7.5px;" v-if="showImport">
            <a class="dropdown-item text-black text-left cursor-pointer bg-white bg-shadow p-3 px-3" href="tickets/import">
              <small>Import from file</small>
            </a>
          </div>
        </div>
      </div>
      <SearchComponent
        @toggleSideOptions="toggleSideOptions($event)"
        @searchRecord="searchTicket($event)"
        :placeholder="'Search tickets by title or ticket number'"
        :showSideOptions="true"
        :isRealTime="false"
        :hasFilters="hasFilters"
      ></SearchComponent>
      <TicketListComponent
        @hideSideOptions="hideSideOptions($event)"
        @getTickets="getTickets($event)"
        :tickets="tickets"
        :ticketCount="ticketCount"
        :page="page"
        :busy="busy"
      ></TicketListComponent>
      <modal
        name="ticket-lookup"
        :transition="`fade`"
        :width="`350px`"
        :height="`auto`"
        :scrollable="false"
        :clickToClose="false"
        class="ticket-lookup"
      >
        <TicketLookUpComponent @toggleLookUp="toggleLookUp($event)"></TicketLookUpComponent>
      </modal>
    </div>
</template>

<script>
  import moment from 'moment'
  import TicketListComponent from './TicketList.vue'
  import SearchComponent from '../Search.vue'
  import TicketLookUpComponent from './TicketLookUp.vue'

  export default {
    components: {
      TicketListComponent,
      SearchComponent,
      TicketLookUpComponent
    },
    props: ['parameters'],
    data() {
      return {
        user: this.$userData ? this.$userData.user : {},
        page: 1,
        busy: false,
        search: '',
        tickets: [],
        ticketStatistics: {
          totalClosed: 0,
          totalCount: 0,
          totalOpen: 0
        },
        ticketParams: {
          dateRange: this.$defaultDates
        },
        ticketCount: 0,
        ticketTab: 'all',
        errors: 0,
        showImport: false,
        hasFilters: false
      }
    },
    methods: {
      loader: function(val){
        this.$emit('loader', val)
      },
      toggleSideOptions: function(){
        this.$emit('toggleSideOptions', 'showTicketsOptions')
      },
      hideSideOptions: function(){
        this.$emit('hideSideOptions', 'showTicketsOptions')
      },
      toggleImport: function(){
        this.showImport = !this.showImport
      },
      setParamsList: function(val){
        this.ticketTab = val;
        this.page = 1;
        this.tickets = []
        this.getTickets(false, true);
      },
      searchTicket: function(val){
        this.search = val;
        this.setParamsList('all')
      },
      async getTickets(willConcatinate = false, isInitial = false) {
        this.ticketParams.list = this.ticketTab;
        this.ticketParams.search = this.search;
        this.busy = true;
        if(this.errors > 5)
          this.busy = true
        if(!willConcatinate || isInitial) this.loader(true)
        try {
          const response = await this.$http.post(
            `${this.$apiEndpoint}/v1/tickets?page=${this.page}&willSkip=true`,
            this.ticketParams,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          if(response){
            this.errors = 0
            if(willConcatinate)
              this.tickets = this.tickets.concat(response.data.tickets.filter(r => !r.client.archived))
            else
              this.tickets = response.data.tickets.filter(r => !r.client.archived)
            this.ticketCount = response.data.tickets.length;
            this.ticketStatistics = {
              totalClosed: response.data.totalClosed,
              totalCount: response.data.totalCount,
              totalOpen: response.data.totalOpen
            }
            if (this.page < response.data.totalPage)
              this.busy = false;
            else
              this.busy = true;
            this.page += 1;
          }
        } catch (error) {
          console.log(error)
          this.busy = false;
        }
        if(!willConcatinate) this.loader(false)
      },
      newTicket: function(){
        this.hideSideOptions()
        this.$router.push('/tickets/new')
      },
      toggleLookUp: function(){
        this.$modal.toggle('ticket-lookup')
      }
    },
    watch: {
      parameters: function(){
        this.ticketParams = this.parameters.filters
        this.hasFilters = localStorage.getItem('ticketFilters') ? true : false
        this.page = 1;
        this.tickets = [];
        this.getTickets(false, true);
      }
    },
    mounted: function() {
      document.title = `Tickets | iRipple Helpdesk`;
      if(this.parameters && this.parameters.filters){
        this.ticketParams = this.parameters.filters
        this.hasFilters = true
      }
      this.getTickets(false, true);
    },
    filters: {
      dateEstimate(date) {
        return moment(date, 'YYYY/MM/DD HH:mm:ss').fromNow();
      },
      priorityName(val) {
        switch(parseInt(val)){
          case 1: return 'Critical'
          case 2: return 'Major'
          case 3: return 'Moderate'
          case 4: return 'Low'
          default: return 'Unclassified'
        }
      }
    }
  }
</script>
